/* App.css */

body {
  margin: 0;
}

.App {
  text-align: left;
  color: white;
  opacity: 100%;
  background: linear-gradient(135deg, #ff7e5f, #feb47b, #ffadec, #feb47b, #ffffda);
  background-size: 400% 400%;
  background-attachment: fixed;
  animation: gradientMotion 8s infinite alternate;
  min-height: 100vh; /* Set min-height to 100vh for full height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#heart {
  position: relative;
  width: 100px;
  height: 90px;
  margin-top: 10px;
  mix-blend-mode: color-dodge;
}

#heart::before, #heart::after {
  content: "";
  position: absolute;
  top: 0;
  width: 52px;
  height: 80px;
  border-radius: 50px 50px 0 0;
  background: red;
}

#heart::before {
  left: 50px;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}

#heart::after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}

.header p {
  font-size: 5em;
  font-weight: 100;
}

@keyframes gradientMotion {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.container {
  margin-top: auto;
  text-align: center; /* Adjust as needed */
  padding: 20px;
}

.contact {
  position: relative;
  bottom: 0;
  right: 0;
  padding: 25px;
  text-align: right;
  font-size: 3em;
  font-weight: 100;
  border: #ffffff;
  vertical-align: bottom;
}

.contact a {
  color: #ffffff;
}
